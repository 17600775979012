<template>
  <v-dialog v-model="showDialog" persistent max-width="500px">
    <v-card>
      <v-card-title>
        <span>Upload Change Detection Set</span>
      </v-card-title>
      <v-card-text class="py-0">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="form.name"
                  label="Name"
                  dense
                  :rules="[v => !!v || 'This field is required']"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="form.quarter"
                  label="Quarter"
                  dense
                  :items="[1, 2, 3, 4]"
                  :rules="[v => !!v || 'This field is required']"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="form.year"
                  label="Year"
                  dense
                  :items="[2023, 2024, 2025, 2026]"
                  :rules="[v => !!v || 'This field is required']"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="form.beforeRasterLayerId"
                  label="Before Raster Layer"
                  dense
                  :items="layers"
                  item-text="name"
                  item-value="id"
                  :rules="[v => !!v || 'This field is required']"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="form.currentRasterLayerId"
                  label="Current Raster Layer"
                  dense
                  :items="layers"
                  item-text="name"
                  item-value="id"
                  :rules="[v => !!v || 'This field is required']"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <file-upload
                  v-if="showDialog"
                  :extensions="extensions.map(e => e.name)"
                  :accept="extensions.map(e => '.' + e.name).join(',')"
                  style="border-radius: 9px; width: 100%; height: 130px; border: 1px dashed var(--v-primary-base)"
                  ref="upload"
                  v-model="files"
                  @input-filter="inputFilter"
                  :drop="true"
                >
                  <template>
                    <div style="cursor: pointer; transform: translate(0, 50%)">
                      <v-icon>{{ icons.mdiFileUploadOutline }}</v-icon>
                      <p class="mt-2 font-weight-bold">Drag file to here or browse</p>
                    </div>
                    <p
                      style="position: absolute; bottom: 0; width: 100%; font-size: 13px; z-index: 2"
                      class="mb-0 text-center py-2"
                      v-if="files.length"
                    >
                      {{ files[0].name }} ({{ files[0].size | size }})
                      <v-icon @click="files = []" size="16" color="error">{{ icons.mdiDeleteOutline }}</v-icon>
                    </p>
                  </template>
                </file-upload>
                <span style="color: var(--v-error-base); font-size: 13px"
                  >(*) Result Vector: SHP zip, geojson (up to 10 MB)</span
                >
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeDialog"> Cancel </v-btn>
        <v-btn color="primary" text @click="upload"> Upload </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import VueUploadComponent from 'vue-upload-component'
import { mdiFileUploadOutline, mdiCheckCircleOutline, mdiDeleteOutline } from '@mdi/js'
import { index as getLayers } from '@/api/layer'
import { uploadChangeDetectionSet } from '@/api/change-detection'
export default {
  props: {
    showDialog: Boolean,
  },
  components: {
    'file-upload': VueUploadComponent,
  },
  data() {
    return {
      icons: { mdiFileUploadOutline, mdiCheckCircleOutline, mdiDeleteOutline },
      valid: true,
      layers: [],
      form: {
        name: null,
        quarter: null,
        year: null,
        beforeRasterLayerId: null,
        currentRasterLayerId: null,
      },
      files: [],
      extensions: [
        {
          name: 'zip',
          size: 10 * 1024 * 1024,
          type: 'vector',
        },
        {
          name: 'geojson',
          size: 10 * 1024 * 1024,
          type: 'vector',
        },
      ],
    }
  },
  created() {
    this.getLayers()
  },
  methods: {
    inputFilter(file, _, prevent) {
      const extension = this.extensions.find(e => e.name === file.name.split('.').pop())
      if (!extension) {
        this.$message(`Invalid file type`, 'error')
        prevent()
      }
      if (file.size > extension.size) {
        this.$message(`File size is too large`, 'error')
        prevent()
      }
      file.type = extension.type
    },
    closeDialog() {
      this.$emit('update:showDialog', false)
      this.files = []
      this.$refs.form.reset()
    },
    async getLayers() {
      try {
        this.$loader(true)
        const { data } = await getLayers({ sortBy: 'id:desc', type: 'raster' })
        this.layers = data
      } catch (error) {
        console.error(error)
      } finally {
        this.$loader(false)
      }
    },
    async upload() {
      try {
        await this.$refs.form.validate()
        if (!this.valid) return
        if (this.files.length === 0) {
          this.$message('File is required', 'error')
          return
        }
        this.$loader(true)
        const formData = new FormData()
        formData.append('name', this.form.name)
        formData.append('quarter', this.form.quarter)
        formData.append('year', this.form.year)
        formData.append('beforeRasterLayerId', this.form.beforeRasterLayerId)
        formData.append('currentRasterLayerId', this.form.currentRasterLayerId)
        formData.append('file', this.files[0].file)
        await uploadChangeDetectionSet(formData)
        this.closeDialog()
      } catch (error) {
        console.log(error)
      } finally {
        this.$loader(false)
      }
    },
  },
}
</script>
