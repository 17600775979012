var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Fragment',[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.showCatalog),expression:"showCatalog"}],style:({
      position: 'absolute',
      zIndex: 1,
      top: '50%',
      transform: 'translate(-50%, -50%)',
      left: _vm.catalogWidth,
    }),attrs:{"title":"Hide list view","color":"primary","x-small":"","fab":""},on:{"click":function($event){return _vm.$emit('update:showCatalog', false)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiChevronDoubleLeft))])],1),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showCatalog),expression:"!showCatalog"}],style:({
      position: 'absolute',
      zIndex: 1,
      bottom: '20px',
      left: '20px',
    }),attrs:{"title":"Show list view","color":"primary","small":"","fab":""},on:{"click":function($event){return _vm.$emit('update:showCatalog', true)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDatabase))])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }