<template>
  <div>
    <v-card outlined>
      <v-card-text class="py-2">
        <div class="d-flex">
          <span style="font-size: 1rem" class="font-weight-bold">ID: {{ result.id }} </span>
          <div class="ml-auto">
            <v-icon title="Zoom to" size="18" @click="$emit('on-zoom')">{{ icons.mdiCrosshairs }}</v-icon>
            <v-icon title="Show detail" size="18" @click="$emit('on-show-result')" right>{{
              icons.mdiInformationOutline
            }}</v-icon>
            <v-icon
              title="Delete"
              size="18"
              :disabled="![1, 2].includes(result.cdStatus.id)"
              @click="destroy"
              right
              v-if="role === 'admin' && !result.deletedAt"
              >{{ icons.mdiDeleteOutline }}</v-icon
            >
            <v-icon title="Restore" size="18" @click="restore" right v-if="role === 'admin' && result.deletedAt">{{
              icons.mdiRestore
            }}</v-icon>
            <v-icon right color="error" title="Toggle showing reports" size="20" @click="toggleShowReports">{{
              result.showReports ? icons.mdiChevronUp : icons.mdiChevronDown
            }}</v-icon>
          </div>
        </div>
        <div class="d-flex mt-3">
          <v-chip :color="result.cdClassType.color" small outlined> {{ result.cdClassType.name }}</v-chip>
          <span class="font-italic ml-auto" small outlined>W{{ result.ward.name }} - P{{ result.plotNumber }}</span>
        </div>
        <div class="d-flex mt-2">
          <v-chip color="info" small outlined>{{ result.cdStatus.name }}</v-chip>
          <span class="font-italic ml-auto">{{ result.area }} m<sup>2</sup></span>
        </div>
        <div v-if="result.showReports" class="mt-2">
          <span style="font-size: 12px">Reports</span>
          <v-divider class="mt-1"> </v-divider>
          <p v-if="result.reports.length === 0" class="text-center mb-0 mt-2" style="font-size: 12px">
            No report found
          </p>
          <div class="d-flex mt-1 align-center" v-for="report in result.reports" :key="report.id">
            <span style="font-size: 13px" class="font-weight-bold">#{{ report.id }} </span>
            <span v-if="report.isConfirmationReport" style="font-size: 13px" class="font-weight-bold">(C) </span>
            <span v-else style="font-size: 13px" class="font-weight-bold">(V) </span>
            <span class="ml-2" style="font-size: 13px">{{ report.createdAt | date }}</span>
            <div class="ml-auto">
              <v-icon
                title="Toggle show report polygon"
                v-if="report.geometry"
                size="16"
                @click="toggleReportOverlay(report)"
                right
                >{{ report.showOnMap ? icons.mdiCrosshairsOff : icons.mdiCrosshairs }}</v-icon
              >
              <v-icon title="Show report" size="16" @click="$emit('on-show-report', report.id)" right>{{
                icons.mdiInformationOutline
              }}</v-icon>
              <v-icon title="Export PDF" size="18" @click="exportReport(report.id)" right>{{ icons.mdiPdfBox }}</v-icon>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <div style="height: 10px"></div>
  </div>
</template>
<script>
import {
  mdiCrosshairs,
  mdiInformationOutline,
  mdiDeleteOutline,
  mdiChevronDown,
  mdiChevronUp,
  mdiCrosshairsOff,
  mdiPdfBox,
  mdiRestore,
} from '@mdi/js'
import { deleteCdResult, getReports, exportReport, restore } from '@/api/change-detection'
import EventBus from '@/services/event-bus'
import GeoJSON from 'ol/format/GeoJSON'
import { Feature } from 'ol'
import { mapState } from 'vuex'
export default {
  props: {
    result: Object,
    cdSet: Object,
  },

  data() {
    return {
      icons: {
        mdiCrosshairs,
        mdiInformationOutline,
        mdiDeleteOutline,
        mdiChevronDown,
        mdiChevronUp,
        mdiCrosshairsOff,
        mdiPdfBox,
        mdiRestore,
      },
    }
  },
  inject: ['mapId'],
  computed: {
    ...mapState('auth', ['user']),
    role() {
      return this.user?.role?.code || 'field_officer'
    },
  },
  methods: {
    toggleReportOverlay(report) {
      if (!report.showOnMap) {
        report.feature = new Feature(
          new GeoJSON().readGeometry(report.geometry, {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857',
          }),
        )
        EventBus.$emit(`${this.mapId}-add-overlay`, report.feature, {
          'stroke-color': 'rgba(255, 165, 0, 1)',
          'stroke-width': 2,
        })
        this.$emit('on-zoom', report.geometry)
        report.showOnMap = true
      } else {
        EventBus.$emit(`${this.mapId}-clear-overlay`, report.feature)
        report.showOnMap = false
      }
    },

    async destroy() {
      try {
        const { value: remark } = await this.$prompt('Are you sure when deleting this detection?', 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          inputType: 'textArea',
          inputPlaceholder: 'Enter your remark',
          inputValidator: value => !!value,
          inputErrorMessage: 'Remark is required',
          type: 'warning',
        })
        this.$loader(true)
        await deleteCdResult(this.result.id, remark)
        this.$message('Deleted Successfully', 'success')
        this.$emit('deleted')
      } catch (error) {
        console.log(error)
      } finally {
        this.$loader(false)
      }
    },

    async restore() {
      try {
        await this.$confirm('Are you sure when restoring this detection?', 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
        })
        this.$loader(true)
        await restore(this.result.id)
        this.$message('Restored Successfully', 'success')
        this.$emit('restored')
      } catch (error) {
        console.log(error)
      } finally {
        this.$loader(false)
      }
    },

    async getReports() {
      try {
        this.$loader(true)
        const { data } = await getReports(this.result.id, {
          select: 'id*createdAt*geometry*isConfirmationReport',
        })
        this.result.showReports = true
        this.result.reports = data.map(r => ({ ...r, showOnMap: false, feature: null }))
      } catch (error) {
        console.log(error)
      } finally {
        this.$loader(false)
      }
    },

    toggleShowReports() {
      if (!this.result.showReports) {
        this.getReports()
      } else {
        this.reports = []
        this.result.showReports = false
      }
    },

    async exportReport(id) {
      try {
        this.$loader(true)
        const data = await exportReport(id)
        const url = URL.createObjectURL(
          new Blob([data], {
            type: 'application/pdf',
          }),
        )
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `cds-${this.cdSet.id}-report-${this.result.id}.pdf`)
        document.body.appendChild(link)
        link.click()
      } finally {
        this.$loader(false)
      }
    },
  },
}
</script>
