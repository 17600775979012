<template>
  <DragResize
    :active="!!result"
    :parent-id="mapId"
    :to="`${mapId}-draggable-elements`"
    :w="350"
    :h="330"
    :x="10"
    :y="10"
    :z="2"
    :minw="350"
    :minh="330"
    dragHandle=".drag-result-detail"
    preventActiveBehavior
  >
    <div
      class="drag-result-detail"
      style="position: absolute; top: 0; height: 40px; width: calc(100% - 40px); z-index: 3; cursor: move"
    ></div>
    <v-card width="100%" height="100%">
      <v-card-title
        flat
        tile
        style="height: 40px; border-bottom: 1px solid lightgray; cursor: move; font-size: 16px"
        class="py-0 d-flex"
      >
        <v-icon size="18">{{ icons.mdiAdjust }}</v-icon>
        <span class="ml-2">Detection: {{ resultId }}</span>

        <v-icon title="Close" size="18" style="margin-left: auto" @click="close">{{ icons.mdiClose }}</v-icon>
      </v-card-title>
      <v-tabs class="elevation-0" height="40" grow v-model="tab">
        <v-tab>Info</v-tab>
        <v-tab>Audit Trails</v-tab>
        <v-tab>Files</v-tab>
      </v-tabs>
      <v-card-text
        v-show="tab === 0"
        v-if="result"
        class="pb-0 mt-4"
        style="height: calc(100% - 140px); overflow-y: auto"
      >
        <v-row align="center" dense>
          <v-col cols="5" class="font-weight-bold"> ID </v-col>
          <v-col cols="7" class="d-flex">
            <span>{{ result.id }} </span>
            <v-icon size="20" title="Refresh" class="ml-auto" @click="onResultChanged">{{ icons.mdiReload }}</v-icon>
          </v-col>
          <v-col cols="5" class="font-weight-bold"> Ward </v-col>
          <v-col cols="7">
            <v-autocomplete
              class="mt-0"
              v-if="role === 'admin'"
              :readonly="result.cdStatusId !== 1 && result.cdStatusId !== 2"
              v-model="form.wardId"
              :items="wards"
              dense
              hide-details
              item-text="name"
              item-value="id"
            ></v-autocomplete>
            <span v-else>{{ result.ward.name }}</span>
          </v-col>
          <v-col cols="5" class="font-weight-bold"> Plot Number </v-col>
          <v-col cols="7">
            <v-autocomplete
              class="mt-0"
              v-if="role === 'admin'"
              :readonly="result.cdStatusId !== 1 && result.cdStatusId !== 2"
              v-model="form.plotNumber"
              :items="plots"
              dense
              hide-details
            ></v-autocomplete>
            <span v-else>{{ result.plotNumber }}</span>
          </v-col>
          <v-col cols="5" class="font-weight-bold"> Status </v-col>
          <v-col cols="7">
            <v-autocomplete
              class="mt-0"
              v-if="role === 'admin'"
              v-model="form.statusId"
              :items="cdStatuses"
              dense
              hide-details
              item-text="name"
              item-value="id"
            ></v-autocomplete>
            <span v-else>{{ result.cdStatus.name }}</span>
          </v-col>
          <v-col cols="5" class="font-weight-bold"> Detection Type </v-col>
          <v-col cols="7">
            <v-autocomplete
              class="mt-0"
              :readonly="result.cdStatusId !== 1 && result.cdStatusId !== 2"
              v-if="role === 'admin'"
              v-model="form.cdClassTypeId"
              :items="cdClassificationTypes"
              dense
              hide-details
              item-text="name"
              item-value="id"
            ></v-autocomplete>
            <span v-else>{{ result.cdClassType.name }}</span>
          </v-col>
          <v-col cols="5" class="font-weight-bold"> Land Type </v-col>
          <v-col cols="7">
            <v-autocomplete
              class="mt-0"
              :readonly="result.cdStatusId !== 1 && result.cdStatusId !== 2"
              v-if="role === 'admin'"
              v-model="form.landType"
              :items="['GMA', 'GMK', 'GM','Other']"
              dense
              hide-details
            ></v-autocomplete>
            <span v-else>{{ result.landType }}</span>
          </v-col>
          <v-col cols="5" class="font-weight-bold"> Type </v-col>
          <v-col cols="7"> {{ result.type || 'Undefined' }} </v-col>
          <v-col cols="5" class="font-weight-bold"> Area (m<sup>2</sup>) </v-col>
          <v-col cols="7"> {{ result.area }} </v-col>
          <!-- <v-col cols="5" class="font-weight-bold"> Village Name </v-col>
          <v-col cols="7"> {{ result.villageNm }} </v-col>
          <v-col cols="5" class="font-weight-bold"> Anchal Name </v-col>
          <v-col cols="7"> {{ result.anchalNm }} </v-col>
          <v-col cols="5" class="font-weight-bold"> Mouza Name </v-col>
          <v-col cols="7"> {{ result.mouzaNm }} </v-col> -->
          <template v-if="result.deletedAt">
            <v-col cols="5" class="font-weight-bold"> Deleted At </v-col>
            <v-col cols="7"> {{ result.deletedAt | datetime }} </v-col>
            <v-col cols="5" class="font-weight-bold"> Remark </v-col>
            <v-col cols="7"> {{ result.cdResultRemarks[0]?.remark }} </v-col>
          </template>
        </v-row>
      </v-card-text>
      <v-card-text
        v-show="tab === 1"
        v-if="result"
        class="pb-0 mt-4"
        style="height: calc(100% - 140px); overflow-y: auto"
      >
        <v-timeline v-if="auditTrails.length" dense align-top>
          <v-timeline-item right v-for="auditTrail in auditTrails" :key="auditTrail.id" small>
            <v-row dense justify="space-between">
              <v-col cols="7">{{ auditTrail.cdStatus.name }}</v-col>
              <v-col class="text-right" cols="5"> {{ auditTrail.createdAt | datetime }}</v-col>
            </v-row>
          </v-timeline-item>
        </v-timeline>
        <p v-else class="text-center">No action found</p>
      </v-card-text>
      <v-card-text
        v-show="tab === 2"
        v-if="result"
        class="pb-0 mt-4"
        style="height: calc(100% - 140px); overflow-y: auto"
      >
        <h4 class="mt-2">Documents</h4>
        <DocumentAttachment
          :documents="attachments.documents"
          v-if="attachments.documents.length > 0"
          :delete-callback="deleteChangeDetectionResultFile"
          @on-deleted="onResultChanged"
        />
        <p v-else class="text-center mb-0">No document found</p>
      </v-card-text>
      <v-card-actions v-if="result">
        <v-spacer></v-spacer>
        <v-btn
          v-if="role === 'admin' && tab === 0"
          color="primary"
          text
          @click="updateResult"
          :disabled="!!result.deletedAt"
          >Save</v-btn
        >
        <v-btn
          v-if="role === 'admin' && tab === 2"
          color="primary"
          text
          @click="showChangeDetectionResultUploadFilesDialog = true"
          >Upload</v-btn
        >
        <v-btn color="primary" text @click="close">Close</v-btn>
      </v-card-actions>
      <ChangeDetectionResultUploadFilesDialog
        :show-dialog.sync="showChangeDetectionResultUploadFilesDialog"
        :result-id="resultId"
        @on-update="onResultChanged"
      />
    </v-card>
  </DragResize>
</template>
<script>
import DragResize from '@/components/DragResize.vue'
import { mdiAdjust, mdiClose, mdiReload } from '@mdi/js'
import { getResult, updateResult, deleteChangeDetectionResultFile } from '@/api/change-detection'
import { mapState } from 'vuex'
import ChangeDetectionResultUploadFilesDialog from './ChangeDetectionResultUploadFilesDialog.vue'
import ImageAttachment from '../attachments/Image.vue'
import DocumentAttachment from '../attachments/Document.vue'
export default {
  props: { resultId: Number, cdClassificationTypes: Array },
  components: { DragResize, ChangeDetectionResultUploadFilesDialog, ImageAttachment, DocumentAttachment },
  data() {
    return {
      icons: { mdiAdjust, mdiClose, mdiReload },
      showChangeDetectionResultUploadFilesDialog: false,
      deleteChangeDetectionResultFile,
      auditTrails: [],
      attachments: [],
      tab: 0,
      cdStatuses: [],
      result: null,
      form: {
        wardId: null,
        statusId: null,
        plotNumber: null,
        cdClassTypeId: null,
        landType: null,
      },
    }
  },
  inject: ['mapId'],

  computed: {
    ...mapState('auth', ['user']),
    ...mapState('category', ['wards']),
    plots() {
      return this.wards.find(w => w.id === this.form.wardId)?.plots || []
    },
    role() {
      return this.user?.role?.code || 'field_officer'
    },
  },

  watch: {
    'form.wardId'() {
      this.form.plotNumber = null
    },
    resultId() {
      this.onResultChanged()
    },
  },

  methods: {
    async onResultChanged() {
      if (!this.resultId) {
        this.result = null
        return
      }
      await this.getResult()
      this.form.wardId = this.result.wardId
      this.form.statusId = this.result.cdStatusId
      this.form.cdClassTypeId = this.result.cdClassTypeId
      this.form.landType = this.result.landType
      this.$nextTick(() => {
        this.form.plotNumber = this.result.plotNumber
      })
    },

    close() {
      this.$emit('on-close')
    },

    async getResult() {
      try {
        this.$loader(true)
        const { data } = await getResult(this.resultId)
        this.auditTrails = data.auditTrails
        this.cdStatuses = data.cdStatuses
        this.result = data.cdResult
        this.attachments.images = data.cdResult.cdResultAttachments.filter(attachment => attachment.type === 'image')
        this.attachments.documents = data.cdResult.cdResultAttachments.filter(attachment => attachment.type === 'doc')
      } finally {
        this.$loader(false)
      }
    },

    async updateResult() {
      if (!this.form.plotNumber) {
        return this.$message('Plot Number is required', 'error')
      }
      try {
        this.$loader(true)
        await updateResult(this.result.id, this.form)
        this.getResult()
        this.$message('Updated Successfully', 'success')
        this.$emit('on-updated', this.form)
      } finally {
        this.$loader(false)
      }
    },
  },
}
</script>
