<template>
  <v-card-text class="mt-2 pb-0" style="height: calc(100% - 56px)">
    <v-text-field
      @keyup.enter="getLayers"
      v-model="filter.search"
      :append-icon="icons.mdiSearchWeb"
      hide-details
      label="Search by name"
      clearable
    ></v-text-field>
    <v-select
      @change="getLayers"
      v-model="filter.type"
      hide-details
      :items="['raster', 'vector']"
      label="Type"
      clearable
    ></v-select>
    <v-checkbox
      hide-details
      dense
      @change="getLayers"
      v-model="filter.isFavorite"
      label="Show only favorite layers"
    ></v-checkbox>
    <v-checkbox hide-details dense v-model="showPlotOnMap" label="Show GOV Plots"></v-checkbox>
    <p class="my-1 d-flex align-center pr-3 pl-2">
      <span>Layers</span>
      <v-btn icon class="ml-auto"
        ><v-icon @click="getLayers" title="Refresh">{{ icons.mdiRefresh }}</v-icon></v-btn
      >
    </p>
    <v-list dense style="overflow-y: auto; height: calc(100% - 210px)" class="pt-0">
      <p v-show="layers.length === 0" class="text-center">No layer found</p>
      <v-list-item v-for="layer in layers" :key="layer.id">
        <v-list-item-icon>
          <v-icon>{{ layer | layerType }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="d-flex align-center">
            <span>{{ layer.name }}</span>
            <div class="ml-auto">
              <v-btn @click="toggleFavorite(layer)" icon x-small>
                <v-icon color="primary" title="Toggle favorite layer">{{
                  layer.isFavorite ? icons.mdiStar : icons.mdiStarOutline
                }}</v-icon></v-btn
              >
              <v-btn @click="download(layer)" icon x-small>
                <v-icon title="Download layer">{{ icons.mdiDownload }}</v-icon></v-btn
              >
              <v-btn :disabled="layerIds.includes(layer.id)" @click="addToMap(layer)" icon x-small>
                <v-icon title="Add to map"> {{ icons.mdiMap }} </v-icon>
              </v-btn>
            </div>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card-text>
</template>
<script>
import {
  mdiSearchWeb,
  mdiStarOutline,
  mdiStar,
  mdiMap,
  mdiVectorRectangle,
  mdiVectorLine,
  mdiRecordCircleOutline,
  mdiRefresh,
  mdiDownload,
} from '@mdi/js'
import { index, toggleFavorite } from '@/api/layer'
import EventBus from '@/services/event-bus'
import { mapState } from 'vuex'
import { getLayer as getPlotLayer } from '@/api/plot'
import { show as getPlot } from '@/api/plot'
import GeoJSON from 'ol/format/GeoJSON'
export default {
  data() {
    return {
      icons: {
        mdiSearchWeb,
        mdiStarOutline,
        mdiRefresh,
        mdiMap,
        mdiVectorRectangle,
        mdiVectorLine,
        mdiRecordCircleOutline,
        mdiStar,
        mdiDownload,
      },
      tab: 0,
      layers: [],
      filter: {
        search: '',
        isFavorite: false,
        type: null,
        sortBy: 'id:desc',
      },
      plotLayer: null,
    }
  },
  inject: ['mapId'],
  computed: {
    ...mapState('map', ['layerIds']),
    showPlotOnMap: {
      get() {
        if (!this.plotLayer) return false
        return this.layerIds.includes(this.plotLayer.id)
      },
      set(value) {
        if (value) EventBus.$emit(`${this.mapId}-add-layer`, this.plotLayer, { color: '#2196F3' })
        else EventBus.$emit(`${this.mapId}-delete-layer`, this.plotLayer.id)
      },
    },
  },
  methods: {
    download(layer) {
      window.open(`/api/layers/${layer.id}/download`, '_blank')
    },

    async getPlotLayer() {
      try {
        const { data } = await getPlotLayer()
        this.plotLayer = data
      } catch (error) {
        console.error(error)
      }
    },

    async getLayers() {
      try {
        this.$loader(true)
        const { data } = await index(this.filter)
        this.layers = data
      } catch (error) {
        console.error(error)
      } finally {
        this.$loader(false)
      }
    },
    async toggleFavorite(layer) {
      try {
        this.$loader(true)
        await toggleFavorite(layer.id, !layer.isFavorite)
        this.$message('Update favorite layer successfully', 'success')
        this.getLayers()
      } catch (error) {
        console.log(error)
      } finally {
        this.$loader(false)
      }
    },
    addToMap(layer) {
      EventBus.$emit(`${this.mapId}-add-layer`, layer)
    },
    async viewPlotInMap(plotId) {
      if (!this.showPlotOnMap) this.showPlotOnMap = true
      const { data: plot } = await getPlot(plotId)
      console.log(plot.geometry);
      EventBus.$emit(
        `${this.mapId}-flash-geometry`,
        new GeoJSON().readGeometry(plot.geometry, {
          dataProjection: 'EPSG:4326',
          featureProjection: 'EPSG:3857',
        }),
      )

    },
  },
  mounted() {
    const { layer, plotId } = this.$route.params
    if (layer) this.addToMap(layer)
    EventBus.$on(`view-layer-in-map`, this.addToMap)
    this.getLayers()
    this.getPlotLayer().then(() => {
      if (plotId) {
        this.viewPlotInMap(plotId)
      }
      EventBus.$on(`view-plot-in-map`, this.viewPlotInMap)
    })
  },

  beforeDestroy() {
    EventBus.$off(`view-layer-in-map`, this.addToMap)
    EventBus.$off(`view-plot-in-map`, this.viewPlotInMap)
  },
}
</script>
