<template>
  <v-card-text style="height: calc(100% - 56px)">
    <div class="d-flex align-center mt-2">
      <v-select
        :prepend-icon="icons.mdiCompare"
        :items="changeDetectionSets"
        v-model="currentChangeDetectionSet"
        item-text="name"
        return-object
        label="Change Detection Set"
        :hint="
          currentChangeDetectionSet ? `Q${currentChangeDetectionSet.quarter} - ${currentChangeDetectionSet.year}` : ''
        "
        hide-details
      >
        <template v-slot:selection="{ item }">
          <span>{{ item.name }}</span>
          <span class="ml-2" style="font-size: 11px; line-height: 11px">Q{{ item.quarter }} - {{ item.year }}</span>
        </template>
        <template v-slot:item="{ item }">
          <span>{{ item.name }}</span>
          <span class="ml-2" style="font-size: 11px; line-height: 11px">Q{{ item.quarter }} - {{ item.year }}</span>
        </template>
        <template v-slot:append-outer>
          <v-btn
            v-if="role === 'admin'"
            small
            icon
            @click="showChangeDetectionSetCreateDialog = true"
            title="Upload Change Detection Set"
          >
            <v-icon>{{ icons.mdiUploadOutline }}</v-icon>
          </v-btn>
        </template>
      </v-select>
      <ChangeDetectionSetCreateDialogVue :show-dialog.sync="showChangeDetectionSetCreateDialog" />
    </div>
    <v-text-field
      @click:clear="getChangeDetectionResults(null)"
      @keyup.enter="getChangeDetectionResults(filter.id)"
      v-model.number="filter.id"
      :prepend-icon="icons.mdiSearchWeb"
      label="Search by ID"
      clearable
      type="number"
      class="mt-3"
      hide-details
      dense
    >
      <template v-slot:append-outer>
        <ChangeDetectionFilter
          :params="filter"
          :options="{ cdStatuses, cdClassificationTypes, cdTypes }"
          :width="310"
          :menu-props="{ offsetX: true, maxHeight: 250, right: true }"
          :close-on-content-click="false"
          @on-reset="resetFilter"
          @change="getChangeDetectionResults()"
        />
      </template>
    </v-text-field>
    <div class="d-flex flex-row-reverse align-center mt-2" v-if="currentChangeDetectionSet">
      <v-menu offset-y :close-on-content-click="false" left nudge-bottom="10">
        <template v-slot:activator="{ on, attrs }">
          <v-icon class="ml-1" size="20" title="Add to Map" v-bind="attrs" v-on="on">{{ icons.mdiMap }}</v-icon>
        </template>
        <v-card>
          <v-card-text>
            <v-checkbox dense hide-details v-model="showResultOnMap" v-mode label="Result"></v-checkbox>
            <v-checkbox dense hide-details v-model="showCurrentRasterOnMap" label="Current Raster"></v-checkbox>
            <v-checkbox dense hide-details v-model="showBeforeRasterOnMap" label="Before Raster"></v-checkbox>
          </v-card-text>
        </v-card>
      </v-menu>

      <v-menu offset-y left nudge-bottom="10">
        <template v-slot:activator="{ on, attrs }">
          <v-icon size="21" title="Export" class="ml-1" v-bind="attrs" v-on="on">{{ icons.mdiPdfBox }}</v-icon>
        </template>
        <v-list dense>
          <v-list-item @click="exportResults('pdf')">
            <v-list-item-title
              ><v-icon size="18" left>{{ icons.mdiFilePdfBox }}</v-icon> <span>PDF</span></v-list-item-title
            >
          </v-list-item>
          <v-list-item @click="exportResults('csv')">
            <v-list-item-title
              ><v-icon size="18" left>{{ icons.mdiFileExcel }}</v-icon> <span>CSV</span></v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>

      <v-icon v-if="role === 'admin'" @click="activateAddingResult" size="20" title="Add new detection" class="ml-1">{{
        icons.mdiPlus
      }}</v-icon>
      <v-menu offset-y left nudge-bottom="10">
        <template v-slot:activator="{ on, attrs }">
          <v-icon class="ml-1" size="20" title="Sort" v-bind="attrs" v-on="on">{{ icons.mdiSwapVertical }}</v-icon>
        </template>
        <v-card>
          <v-card-text>
            <v-radio-group class="mt-0" dense hide-details mandatory v-model="sortBy">
              <v-radio label="ID: low to high" value="id:asc"></v-radio>
              <v-radio label="ID: high to low" value="id:desc"></v-radio>
              <v-radio label="Area: low to high" value="area:asc"></v-radio>
              <v-radio label="Area: high to low" value="area:desc"></v-radio>
            </v-radio-group>
          </v-card-text>
        </v-card>
      </v-menu>
      <v-icon @click="getChangeDetectionResults" size="20" title="Refresh" class="ml-1">{{ icons.mdiReload }}</v-icon>
      <span class="mr-auto">Total: {{ results.length }}</span>
    </div>
    <p v-if="results.length === 0" class="text-center mt-3">No detection found</p>
    <DynamicScroller :items="results" :min-item-size="114" style="height: calc(100% - 140px)" class="mt-3">
      <template v-slot="{ item, index, active }">
        <DynamicScrollerItem
          :item="item"
          :active="active"
          :size-dependencies="[item.showReports, item.reports]"
          :data-index="index"
        >
          <ChangeDetectionResult
            @deleted="getChangeDetectionResults"
            @restored="getChangeDetectionResults"
            @on-show-result="selectedResultId = +item.id"
            @on-show-report="selectedReportId = $event"
            @on-zoom="zoomTo(item.geometry)"
            :result="item"
            :cdSet="currentChangeDetectionSet"
          />
        </DynamicScrollerItem>
      </template>
    </DynamicScroller>
    <ChangeDetectionResultDetail
      :result-id="selectedResultId"
      :cdClassificationTypes="cdClassificationTypes"
      @on-close="selectedResultId = null"
      @on-updated="updateResult"
    />
    <ChangeDetectionReport :report-id="selectedReportId" @on-close="selectedReportId = null" />
  </v-card-text>
</template>
<script>
import ChangeDetectionSetCreateDialogVue from './ChangeDetectionSetCreateDialog.vue'
import {
  getChangeDetectionSets,
  getChangeDetectionResults,
  exportResults,
  createChangeDetectionResult,
} from '@/api/change-detection'
import { getCdStatuses, getCdClassificationTypes, getCdTypes } from '@/api/category'
import ChangeDetectionResult from './ChangeDetectionResult.vue'
import ChangeDetectionResultDetail from './ChangeDetectionResultDetail'
import ChangeDetectionReport from './ChangeDetectionReport'
import {
  mdiFilePdfBox,
  mdiPlus,
  mdiMap,
  mdiFilterOutline,
  mdiUploadOutline,
  mdiSearchWeb,
  mdiCompare,
  mdiReload,
  mdiSwapVertical,
  mdiFileExcel,
  mdiPdfBox,
} from '@mdi/js'
import EventBus from '@/services/event-bus'
import { mapState } from 'vuex'
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import GeoJSON from 'ol/format/GeoJSON'
import ChangeDetectionFilter from '@/components/change-detection/ChangeDetectionFilter.vue'

export default {
  components: {
    ChangeDetectionResult,
    ChangeDetectionSetCreateDialogVue,
    DynamicScroller,
    DynamicScrollerItem,
    ChangeDetectionResultDetail,
    ChangeDetectionReport,
    ChangeDetectionFilter,
  },
  inject: ['mapId'],
  data() {
    return {
      icons: {
        mdiFilePdfBox,
        mdiPlus,
        mdiMap,
        mdiFilterOutline,
        mdiUploadOutline,
        mdiSearchWeb,
        mdiCompare,
        mdiReload,
        mdiSwapVertical,
        mdiFileExcel,
        mdiPdfBox,
      },
      showChangeDetectionSetCreateDialog: false,
      currentChangeDetectionSet: null,
      changeDetectionSets: [],
      cdStatuses: [],
      cdTypes: [],
      cdClassificationTypes: [],
      showFilter: false,
      filter: {
        id: null,
        wards: [],
        plots: [],
        statuses: [],
        classTypes: [],
        cdTypes: [],
        deleted: false,
        landTypes: [],
      },
      sortBy: 'id:desc',
      total: 0,
      lastPage: 0,
      results: [],
      selectedResultId: null,
      selectedReportId: null,
    }
  },

  computed: {
    ...mapState('map', ['layerIds']),
    ...mapState('auth', ['user']),
    ...mapState('category', ['wards']),
    role() {
      return this.user?.role?.code || 'field_officer'
    },
    resultLayerId() {
      if (!this.currentChangeDetectionSet) return null
      return 'cds-' + this.currentChangeDetectionSet.id
    },
    legend() {
      return this.cdClassificationTypes.map(type => ({
        name: type.name,
        color: type.color,
      }))
    },
    resultLayerStyle() {
      return {
        color: 'var(--v-primary-base)',
        displayType: 'line',
        style: this.cdClassificationTypes.map(type => ({
          filter: ['==', ['get', 'cdClassTypeId'], type.id],
          style: {
            'stroke-color': type.color,
            'stroke-width': 2,
          },
        })),
      }
    },
    showResultOnMap: {
      get() {
        if (!this.currentChangeDetectionSet) return false
        return this.layerIds.includes(this.resultLayerId)
      },
      set(value) {
        if (value)
          EventBus.$emit(
            `${this.mapId}-add-layer`,
            {
              id: this.resultLayerId,
              type: 'result',
              vectorType: 'Polygon',
              name: this.currentChangeDetectionSet.name,
              geojson: this.geojson,
              minZoom: 0,
              maxZoom: 22,
            },
            this.resultLayerStyle,
            this.legend,
          )
        else EventBus.$emit(`${this.mapId}-delete-layer`, this.resultLayerId)
      },
    },
    showCurrentRasterOnMap: {
      get() {
        if (!this.currentChangeDetectionSet) return false
        return this.layerIds.includes(this.currentChangeDetectionSet.currentRasterLayer.id)
      },
      set(value) {
        if (value) EventBus.$emit(`${this.mapId}-add-layer`, this.currentChangeDetectionSet.currentRasterLayer)
        else EventBus.$emit(`${this.mapId}-delete-layer`, this.currentChangeDetectionSet.currentRasterLayer.id)
      },
    },
    showBeforeRasterOnMap: {
      get() {
        if (!this.currentChangeDetectionSet) return false
        return this.layerIds.includes(this.currentChangeDetectionSet.beforeRasterLayer.id)
      },
      set(value) {
        if (value) EventBus.$emit(`${this.mapId}-add-layer`, this.currentChangeDetectionSet.beforeRasterLayer)
        else EventBus.$emit(`${this.mapId}-delete-layer`, this.currentChangeDetectionSet.beforeRasterLayer.id)
      },
    },
    geojson() {
      if (!this.currentChangeDetectionSet) return null
      return {
        type: 'FeatureCollection',
        features: this.results.map(result => {
          const { geometry, ...properties } = result
          return {
            type: 'Feature',
            properties,
            geometry,
          }
        }),
      }
    },
  },
  watch: {
    currentChangeDetectionSet(_, oldValue) {
      if (oldValue) {
        EventBus.$emit(`${this.mapId}-delete-layer`, oldValue.currentRasterLayer.id)
        EventBus.$emit(`${this.mapId}-delete-layer`, oldValue.beforeRasterLayer.id)
        EventBus.$emit(`${this.mapId}-delete-layer`, 'cds-' + oldValue.id)
      }
      this.getChangeDetectionResults().then(() => {
        setTimeout(() => {
          this.showBeforeRasterOnMap = true
          this.showCurrentRasterOnMap = true
          this.showResultOnMap = true
        }, 200)
      })
    },

    geojson() {
      if (this.showResultOnMap) {
        EventBus.$emit(`${this.mapId}-update-vector-layer`, this.resultLayerId, this.geojson)
      }
    },

    sortBy() {
      this.getChangeDetectionResults()
    },
  },

  methods: {
    createResultLayerStyle() {
      return {
        color: 'var(--v-primary-base)',
        displayType: 'line',
        style: {
          'stroke-color': 'yellow',
          'strole-width': 2,
        },
      }
    },

    async getChangeDetectionResults(id = undefined) {
      if (!this.currentChangeDetectionSet) return
      try {
        this.$loader(true)
        if (id === null || typeof id === 'number') {
          this.filter.id = id
        }
        const { data } = await getChangeDetectionResults({
          ...this.filter,
          cdSetId: this.currentChangeDetectionSet.id,
          with: 'cdClassType:id*name*color;cdStatus:id*name;ward:id*name',
          select: 'id*cdClassTypeId*wardId*cdStatusId*area*geometry',
          sortBy: this.sortBy,
        })
        data.forEach(result => {
          result.showReports = false
          result.reports = []
        })
        this.results = []
        this.$nextTick(() => (this.results = data))
        EventBus.$emit(`${this.mapId}-clear-overlay`)
      } catch (error) {
        console.error(error)
      } finally {
        this.$loader(false)
      }
    },

    async getCategories() {
      const [cdStatuses, cdClassificationTypes, cdTypes] = (
        await Promise.all([getCdStatuses(), getCdClassificationTypes(), getCdTypes()])
      ).map(r => r.data)
      this.cdStatuses = cdStatuses
      this.cdClassificationTypes = cdClassificationTypes
      this.cdTypes = cdTypes
    },

    async getChangeDetectionSets(id) {
      try {
        this.$loader(true)
        const { data } = await getChangeDetectionSets({
          sortBy: 'id:desc',
          with: 'currentRasterLayer;beforeRasterLayer',
        })
        this.changeDetectionSets = data
        if (data.length > 0) this.currentChangeDetectionSet = id ? data.find(s => s.id === id) : data[0]
      } catch (error) {
        console.error(error)
      } finally {
        this.$loader(false)
      }
    },

    resetFilter(reload = true) {
      this.filter = {
        id: '',
        wards: [],
        plots: [],
        statuses: [],
        classTypes: [],
        cdTypes: [],
        deleted: false,
      }
      this.showFilter = false
      if (reload) this.getChangeDetectionResults()
    },

    onScroll(e) {
      const { scrollTop, clientHeight, scrollHeight } = e.target
      if (scrollTop + clientHeight >= scrollHeight) {
        if (this.filter.page < this.lastPage) {
          this.filter.page++
          this.getChangeDetectionResults()
        }
      }
    },

    activateAddingResult() {
      EventBus.$emit(
        `${this.mapId}-add-feature`,
        'Polygon',
        [
          {
            label: 'Ward',
            value: 'wardId',
            type: 'select',
            options: this.wards.map(ward => ({ value: ward.id, text: ward.name })),
            rules: [v => !!v || 'This field is required'],
          },
          {
            label: 'Plot Number',
            value: 'plotNumber',
            type: 'plot',
            rules: [v => !!v || 'This field is required'],
          },
          {
            label: 'Detect Type',
            value: 'cdClassTypeId',
            type: 'select',
            options: this.cdClassificationTypes.map(type => ({ value: type.id, text: type.name })),
            rules: [v => !!v || 'This field is required'],
          },
          {
            label: 'Land Type',
            value: 'landType',
            type: 'select',
            options: ['GMA', 'GMK', 'GM', 'Other'],
            rules: [v => !!v || 'This field is required'],
          },
          // {
          //   label: 'Village Name',
          //   value: 'villageNm',
          //   type: 'text',
          //   rules: [],
          // },
          // {
          //   label: 'Anchal Name',
          //   value: 'anchalNm',
          //   type: 'text',
          //   rules: [],
          // },
          // {
          //   label: 'Mouza Name',
          //   value: 'mouzaNm',
          //   type: 'text',
          //   rules: [],
          // },
        ],
        async payload => {
          const { data } = await createChangeDetectionResult(this.currentChangeDetectionSet.id, payload)
          this.$message('Change detection result has been created successfully', 'success')
          return data
        },
      )
    },

    onResultCreated() {
      this.getChangeDetectionResults()
    },

    async exportResults(type) {
      this.$loader(true)
      const { page, ...params } = this.filter
      const data = await exportResults(
        { ...params, cdSetId: this.currentChangeDetectionSet.id, sortBy: this.sortBy },
        type,
      )
      const url = URL.createObjectURL(
        new Blob([data], {
          type: type === 'csv' ? 'text/csv' : 'application/pdf',
        }),
      )
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${this.currentChangeDetectionSet.name}.${type}`)
      document.body.appendChild(link)
      link.click()
      this.$loader(false)
    },

    zoomTo(geometry) {
      EventBus.$emit(
        `${this.mapId}-flash-geometry`,
        new GeoJSON().readGeometry(geometry, {
          dataProjection: 'EPSG:4326',
          featureProjection: 'EPSG:3857',
        }),
      )
    },

    updateResult({ wardId, statusId }) {
      const result = this.results.find(r => +r.id === this.selectedResultId)
      if (!result) return
      result.wardId = wardId
      result.cdStatusId = statusId
      result.cdStatus = this.cdStatuses.find(s => s.id === statusId)
      result.ward = this.wards.find(w => w.id === wardId)
    },

    async viewResultOnMap(id, cdSetId) {
      this.resetFilter(false)
      this.filter.id = id
      if (cdSetId !== this.currentChangeDetectionSet.id) {
        this.currentChangeDetectionSet = this.changeDetectionSets.find(s => s.id === cdSetId)
      } else {
        await this.getChangeDetectionResults()
        const result = this.results[0]
        this.zoomTo(result.geometry)
        this.selectedResultId = +result.id
      }
    },
  },

  mounted() {
    const { resultId, cdSetId } = this.$route.params
    this.filter.id = resultId
    this.getChangeDetectionSets(cdSetId).then(() => {
      this.selectedResultId = +resultId
    })
    this.getCategories()
    EventBus.$on(`${this.mapId}-feature-created`, this.onResultCreated)
    EventBus.$on(`view-result-in-map`, this.viewResultOnMap)
  },

  beforeDestroy() {
    EventBus.$off(`${this.mapId}-feature-created`, this.onResultCreated)
    EventBus.$off(`view-result-in-map`, this.viewResultOnMap)
  },
}
</script>
