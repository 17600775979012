<template>
  <div class="full-height d-flex" style="overflow: hidden">
    <Catalog v-show="showCatalog" :style="{ width: catalogWidth }" :tab.sync="tab" />
    <Map
      :id="mapConfigs.id"
      :tools="mapConfigs.tools"
      :zoom="mapConfigs.zoom"
      :center="mapConfigs.center"
      :style="{ width: mapWidth }"
    />
    <CatalogToggler :show-catalog.sync="showCatalog" :catalog-width="catalogWidth" />
  </div>
</template>

<script>
import Map from '@/components/map/Map.vue'
import Catalog from './catalog/index.vue'
import CatalogToggler from './CatalogToggler.vue'
import { mdiChevronDoubleLeft, mdiDatabase } from '@mdi/js'
import { mapConfigs } from '@/constants/change-detection'
import EventBus from '@/services/event-bus'

export default {
  components: { Map, Catalog, CatalogToggler },

  provide() {
    return { mapId: this.mapConfigs.id }
  },

  data() {
    return {
      tab: undefined,
      mapConfigs,
      showCatalog: true,
      icons: { mdiChevronDoubleLeft, mdiDatabase },
      defaultCatalogWidth: '350px',
    }
  },
  computed: {
    catalogWidth() {
      return this.showCatalog ? this.defaultCatalogWidth : '0px'
    },
    mapWidth() {
      return this.showCatalog ? `calc(100% - ${this.defaultCatalogWidth})` : '100%'
    },
  },
  methods: {
    onLayerAdded(layer) {
      this.$store.commit('map/ADD_LAYER', layer.get('id'))
    },
    onLayerDeleted(layer) {
      this.$store.commit('map/REMOVE_LAYER', layer.get('id'))
    },
  },

  created() {
    EventBus.$on(`${this.mapConfigs.id}-layer-added`, this.onLayerAdded)
    EventBus.$on(`${this.mapConfigs.id}-layer-deleted`, this.onLayerDeleted)
  },

  beforeDestroy() {
    EventBus.$off(`${this.mapConfigs.id}-layer-added`, this.onLayerAdded)
    EventBus.$off(`${this.mapConfigs.id}-layer-deleted`, this.onLayerDeleted)
  },

  beforeRouteEnter(to, _, next) {
    next(vm => {
      if ('tab' in to.params) {
        vm.tab = to.params.tab
      }
    })
  },
}
</script>
