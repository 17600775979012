<template>
  <v-dialog v-model="showDialog" persistent max-width="500px">
    <v-card>
      <v-card-title>
        <span>Upload Complaint Files</span>
      </v-card-title>
      <v-card-text class="py-0">
        <v-container>
          <v-row>
            <v-col cols="12">
              <file-upload
                v-if="showDialog"
                multiple
                :extensions="extensions.map(e => e.name)"
                :accept="extensions.map(e => '.' + e.name).join(',')"
                style="border-radius: 9px; width: 100%; height: 150px; border: 1px dashed var(--v-primary-base)"
                ref="upload"
                v-model="files"
                @input-filter="inputFilter"
                :drop="true"
              >
                <template>
                  <div style="cursor: pointer; transform: translate(0, 50%)">
                    <v-icon>{{ icons.mdiFileUploadOutline }}</v-icon>
                    <p class="mt-2 font-weight-bold">Drag file to here or browse</p>
                    <span style="color: var(--v-error-base); font-size: 13px"
                      >(*) Document: pdf, png, jpg (max 20MB)</span
                    >
                  </div>
                </template>
              </file-upload>

              <p
                v-for="(file, idx) in files"
                :key="file.id"
                style="width: 100%; font-size: 13px; z-index: 2"
                class="mb-0 mt-1"
              >
                {{ file.name }} ({{ file.size | size }})
                <v-icon @click="files.splice(idx, 1)" size="16" color="error">{{ icons.mdiDeleteOutline }}</v-icon>
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeDialog"> Cancel </v-btn>
        <v-btn color="primary" text @click="upload"> Upload </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import VueUploadComponent from 'vue-upload-component'
import { mdiFileUploadOutline, mdiCheckCircleOutline, mdiDeleteOutline } from '@mdi/js'
import { uploadComplaintFiles } from '@/api/complaint'
export default {
  props: {
    showDialog: Boolean,
    complaintId: Number,
  },
  components: {
    'file-upload': VueUploadComponent,
  },
  data() {
    return {
      icons: { mdiFileUploadOutline, mdiCheckCircleOutline, mdiDeleteOutline },
      layers: [],
      form: {
        images: [],
        documents: [],
      },
      files: [],
      extensions: [
        {
          name: 'png',
          size: 20 * 1024 * 1024,
          type: 'doc',
        },
        {
          name: 'jpg',
          size: 20 * 1024 * 1024,
          type: 'doc',
        },
        {
          name: 'pdf',
          size: 20 * 1024 * 1024,
          type: 'doc',
        },
      ],
    }
  },

  methods: {
    inputFilter(file, _, prevent) {
      const extension = this.extensions.find(e => e.name === file.name.split('.').pop())
      if (!extension) {
        this.$message(`Invalid file type`, 'error')
        prevent()
      }
      if (file.size > extension.size) {
        this.$message(`File size is too large`, 'error')
        prevent()
      }
      file.type = extension.type
    },

    closeDialog() {
      this.$emit('update:showDialog', false)
      this.$emit('on-update')
      this.files = []
    },

    async upload() {
      try {
        if (this.files.length === 0) {
          this.$message('Files is required', 'error')
          return
        }
        this.$loader(true)
        const formData = new FormData()
        this.files.forEach(file => {
          formData.append(file.type + 's[]', file.file)
        })
        await uploadComplaintFiles(this.complaintId, formData)
        this.closeDialog()
      } catch (error) {
        console.log(error)
      } finally {
        this.$loader(false)
      }
    },
  },
}
</script>
