<template>
  <div class="d-flex flex-wrap">
    <v-hover v-slot="{ hover }" v-for="video in videos" :key="video.id">
      <div class="mt-3 mr-3 elevation-1 rounded-sm" style="position: relative; width: 100px; height: 70px">
        <img :src="require('@/assets/images/logos/video.png')" width="100%" height="100%" />
        <div
          style="
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            background: rgba(0, 0, 0, 0.2);
            cursor: pointer;
            z-index: 2;
          "
          @click="showVideo(video)"
          v-show="hover"
        ></div>
      </div>
    </v-hover>

    <portal to="overlay">
      <v-overlay style="z-index: 9999" :value="show" @click="close">
        <video v-if="video" :src="video.path" width="500px" controls></video>
      </v-overlay>
    </portal>
  </div>
</template>

<script>
import { mdiClose } from '@mdi/js'
export default {
  props: {
    videos: Array,
  },

  data() {
    return { mdiClose, show: false, video: null }
  },
  methods: {
    showVideo(video) {
      this.video = video
      this.show = true
    },
    close() {
      this.video = null
      this.show = false
    },
  },
}
</script>
