<template>
  <Viewer :images="images" @inited="viewer = $event">
    <template #default="scope">
      <div class="d-flex flex-wrap" style="width: 100%">
        <v-hover v-slot="{ hover }" v-for="(image, index) in scope.images" :key="image.id">
          <div class="mt-3 mr-3 elevation-1 rounded-sm" style="position: relative; width: 80px; height: 80px">
            <img :src="image.path" width="100%" height="100%" />
            <div
              style="
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                background: rgba(0, 0, 0, 0.2);
                cursor: pointer;
                z-index: 2;
              "
              @click="show(index)"
              v-show="hover"
            ></div>
            <v-icon
              v-if="deleteCallback"
              style="position: absolute; bottom: 5px; right: 3px; z-index: 3"
              color="error"
              size="18"
              @click="deleteFile(image.id)"
              v-show="hover"
            >
              {{ icons.mdiDelete }}
            </v-icon>
          </div>
        </v-hover>
      </div>
      <div class="d-flex flex-wrap" style="width: 100%">
        <v-hover v-slot="{ hover }" v-for="doc in docs" :key="doc.id">
          <v-card outlined class="mr-3 mt-3">
            <v-card-title class="py-3 pr-6 pl-2" style="font-size: 13px">
              <v-icon left size="19">{{ icons.mdiFilePdf }}</v-icon>
              <span class="mt-1">{{ doc.filename }}</span></v-card-title
            >
            <div
              style="
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                background: rgba(0, 0, 0, 0.1);
                cursor: pointer;
              "
              @click="showPdf(doc)"
              class="rounded-sm"
              v-show="hover"
            ></div>
            <v-icon
              v-if="deleteCallback"
              style="position: absolute; top: 50%; transform: translateY(-50%); right: 3px; z-index: 3"
              color="error"
              size="18"
              @click="deleteFile(doc.id)"
              v-show="hover"
            >
              {{ icons.mdiDelete }}
            </v-icon>
          </v-card>
        </v-hover>
      </div>

      {{ scope.options }}
    </template>
  </Viewer>
</template>

<script>
import 'viewerjs/dist/viewer.css'
import { component as Viewer } from 'v-viewer'
import pdfImage from '@/assets/images/logos/pdf.png'
import { mdiFilePdf, mdiDelete } from '@mdi/js'
export default {
  props: {
    documents: Array,
    deleteCallback: Function,
  },
  components: { Viewer },
  data() {
    return { pdfImage, icons: { mdiFilePdf, mdiDelete } }
  },

  methods: {
    showPdf(doc) {
      window.open(doc.path, '_blank')
    },
    show(index) {
      this.viewer.index = index
      this.viewer.show()
    },
    async deleteFile(id) {
      try {
        await this.$confirm('Are you sure when deleting this document?', 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
        })
        this.$loader(true)
        await this.deleteCallback(id)
        this.$message('Deleted Successfully', 'success')
        this.$emit('on-deleted')
      } catch (error) {
        console.log(error)
      } finally {
        this.$loader(false)
      }
    },
  },

  computed: {
    images() {
      return this.documents.filter(doc => ['jpg', 'png'].includes(doc.extension))
    },

    docs() {
      return this.documents.filter(doc => ['pdf'].includes(doc.extension))
    },
  },
}
</script>
