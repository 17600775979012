<template>
  <v-card class="full-height">
    <v-tabs :value="tab" @change="$emit('update:tab', $event)" grow>
      <v-tab title="Change Detection"
        ><v-icon>{{ icons.mdiCompare }}</v-icon></v-tab
      >
      <v-tab v-if="role !== 'field_officer'" title="Citizen Complaint"
        ><v-icon>{{ icons.mdiFileAccountOutline }}</v-icon></v-tab
      >
      <v-tab title="Layer Catalog"
        ><v-icon>{{ icons.mdiLayersSearchOutline }}</v-icon></v-tab
      >
    </v-tabs>
    <keep-alive>
      <component @on-tab-change="tab = $event" keep-alive :is="components[tab]"></component>
    </keep-alive>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import LayerCatalog from './LayerCatalog.vue'
import ChangeDetection from './change-detection/index.vue'
import CitizenComplaint from './citizen-complaint/index.vue'
import { mdiCompare, mdiLayersSearchOutline, mdiFileAccountOutline } from '@mdi/js'
export default {
  components: { LayerCatalog, ChangeDetection, CitizenComplaint },
  props: { tab: Number },

  computed: {
    ...mapState('auth', ['user']),
    role() {
      return this.user?.role?.code || 'field_officer'
    },
    components() {
      return this.role === 'field_officer'
        ? [ChangeDetection, LayerCatalog]
        : [ChangeDetection, CitizenComplaint, LayerCatalog]
    },
  },
  data() {
    return {
      icons: {
        mdiCompare,
        mdiLayersSearchOutline,
        mdiFileAccountOutline,
      },
    }
  },
}
</script>
