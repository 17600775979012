<template>
  <DragResize
    :active="!!complaint"
    :parent-id="mapId"
    :to="`${mapId}-draggable-elements`"
    :w="400"
    :h="380"
    :x="10"
    :y="10"
    :z="2"
    :minw="380"
    :minh="350"
    dragHandle=".drag-complaint-detail"
    preventActiveBehavior
  >
    <div
      class="drag-complaint-detail"
      style="position: absolute; top: 0; height: 40px; width: calc(100% - 40px); z-index: 3; cursor: move"
    ></div>
    <v-card width="100%" height="100%">
      <v-card-title
        flat
        tile
        style="height: 40px; border-bottom: 1px solid lightgray; cursor: move; font-size: 16px"
        class="py-0 d-flex"
      >
        <v-icon size="18">{{ icons.mdiAdjust }}</v-icon>
        <span class="ml-2">Complaint: {{ complaintId }}</span>

        <v-icon title="Close" size="18" style="margin-left: auto" @click="close">{{ icons.mdiClose }}</v-icon>
      </v-card-title>
      <v-tabs class="elevation-0" height="40" grow v-model="tab">
        <v-tab>Info</v-tab>
        <v-tab>Attachments</v-tab>
        <v-tab>Audit trails</v-tab>
        <v-tab>Remarks</v-tab>
        <v-tab>Files</v-tab>
      </v-tabs>
      <v-card-text
        v-show="tab === 0"
        v-if="complaint"
        class="pb-0 mt-4"
        style="height: calc(100% - 140px); overflow-y: auto"
      >
        <v-row align="center" dense>
          <v-col cols="5" class="font-weight-bold"> ID </v-col>
          <v-col cols="7" class="d-flex">
            <span>{{ complaint.id }} </span>
            <v-icon size="20" title="Refresh" class="ml-auto" @click="getComplaint">{{ icons.mdiReload }}</v-icon>
          </v-col>
          <v-col cols="5" class="font-weight-bold">Citizen Location </v-col>
          <v-col cols="7" class="d-flex align-center">
            <span v-if="complaint.location">{{ complaint.location.join(', ') }}</span>
            <v-icon @click="zoomToLocation(complaint.location)" class="ml-auto" small v-if="complaint.location">{{
              icons.mdiCrosshairs
            }}</v-icon>
            <span v-else>Undefined</span>
          </v-col>
          <v-col cols="5" class="font-weight-bold"> Location Detail </v-col>
          <v-col cols="7" class="d-flex">
            <span>{{ complaint.name }} </span>
          </v-col>
          <v-col cols="5" class="font-weight-bold"> Ward </v-col>
          <v-col cols="7">
            <span>{{ complaint.ward.name }}</span>
          </v-col>
          <v-col cols="5" class="font-weight-bold"> Status </v-col>
          <v-col cols="7">
            <v-autocomplete
              class="mt-0"
              v-if="role === 'admin'"
              v-model="form.statusId"
              :items="statuses"
              dense
              hide-details
              item-text="name"
              item-value="id"
            ></v-autocomplete>
            <span v-else>{{ complaint.complaintStatus.name }}</span>
          </v-col>
        </v-row>
        <v-row align="center" dense>
          <v-col cols="5" class="font-weight-bold"> Citizen Name </v-col>
          <v-col cols="7">
            <span>{{ complaint.user.name }}</span>
          </v-col>
          <v-col cols="5" class="font-weight-bold"> Mobile Number </v-col>
          <v-col cols="7">
            <a :href="'tel:' + complaint.user.mobileNumber">{{ complaint.user.mobileNumber }}</a>
          </v-col>
          <v-col cols="5" class="font-weight-bold"> Email </v-col>
          <v-col cols="7">
            <a :href="'mailto:' + complaint.user.email">{{ complaint.user.email }}</a>
          </v-col>
          <v-col cols="5" class="font-weight-bold"> Created At </v-col>
          <v-col cols="7">
            <span>{{ complaint.createdAt | datetime }}</span>
          </v-col>
          <v-col cols="5" class="font-weight-bold"> Updated At </v-col>
          <v-col cols="7">
            <span>{{ complaint.updatedAt | datetime }}</span>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text v-show="tab === 1" class="mt-4 pb-0" style="height: calc(100% - 140px); overflow-y: auto">
        <h4>Images</h4>
        <ImageAttachment :images="attachments.images" v-if="attachments.images.length > 0" />
        <p v-else class="text-center mb-0">No image found</p>
        <h4 class="mt-2">Videos</h4>
        <VideoAttachment :videos="attachments.videos" v-if="attachments.videos.length > 0" />
        <p v-else class="text-center mb-0">No video found</p>
      </v-card-text>

      <v-card-text
        v-show="tab === 2"
        v-if="complaint"
        class="mt-4 pb-0"
        style="height: calc(100% - 140px); overflow-y: auto"
      >
        <v-timeline v-if="auditTrails.length" dense align-top>
          <v-timeline-item right v-for="auditTrail in auditTrails" :key="auditTrail.id" small>
            <v-row dense justify="space-between">
              <v-col cols="7">{{ auditTrail.complaintStatus.name }}</v-col>
              <v-col class="text-right" cols="5"> {{ auditTrail.createdAt | datetime }}</v-col>
            </v-row>
          </v-timeline-item>
        </v-timeline>
        <p v-else class="text-center">No action found</p>
      </v-card-text>

      <v-card-text v-show="tab === 3" class="mt-4 pb-0" style="height: calc(100% - 140px); overflow-y: auto">
        <v-timeline dense clipped>
          <v-timeline-item fill-dot class="white--text" color="primary">
            <template v-slot:icon>
              <span>{{ $store.state.auth.user.name | sortName }}</span>
            </template>
            <v-textarea
              no-resize
              rows="2"
              auto-grow
              v-model="remark"
              hide-details
              solo
              placeholder="Leave a remark"
              @keydown.enter="createRemark"
            >
              <template v-slot:append>
                <v-btn class="mx-0" @click="createRemark" icon>
                  <v-icon>{{ icons.mdiPlus }}</v-icon>
                </v-btn>
              </template>
            </v-textarea>
          </v-timeline-item>

          <v-timeline-item color="info" small v-for="remark in remarks" :key="remark.id">
            <v-card class="elevation-2">
              <v-card-text class="py-2">
                <div class="d-flex align-center">
                  <span class="font-weight-bold"> {{ remark.user.name }}</span>
                  <span style="font-size: 12px" class="ml-auto">{{ remark.user.role.name }}</span>
                </div>
                <p class="my-1">{{ remark.remark }}</p>
                <div class="d-flex align-center font-italic" style="font-size: 12px">
                  <v-icon size="13">{{ icons.mdiClockOutline }}</v-icon>
                  <span class="ml-1">{{ remark.createdAt | datetime }}</span>
                </div>
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-card-text>

      <v-card-text
        v-show="tab === 4"
        v-if="complaint"
        class="mt-4 pb-0"
        style="height: calc(100% - 140px); overflow-y: auto"
      >
        <h4 class="mt-2">Documents</h4>
        <DocumentAttachment
          :documents="attachments.files"
          v-if="attachments.files.length > 0"
          :delete-callback="deleteComplaintFile"
          @on-deleted="onComplaintChanged"
        />
        <p v-else class="text-center mb-0">No document found</p>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="role === 'admin' && tab === 0" color="primary" text @click="updateComplaint">Save</v-btn>
        <v-btn v-if="role === 'admin' && tab === 4" color="primary" text @click="showComplaintUploadFilesDialog = true"
          >Upload</v-btn
        >
        <v-btn color="primary" text @click="close">Close</v-btn>
      </v-card-actions>
      <ComplaintUploadFilesDialog
        :show-dialog.sync="showComplaintUploadFilesDialog"
        :complaint-id="complaintId"
        @on-update="onComplaintChanged"
      />
    </v-card>
  </DragResize>
</template>
<script>
import DragResize from '@/components/DragResize.vue'
import { mdiAdjust, mdiClose, mdiReload, mdiPlus, mdiCrosshairs } from '@mdi/js'
import { getComplaint, updateComplaint, getRemarks, createRemark, deleteComplaintFile } from '@/api/complaint'
import { mapState } from 'vuex'
import ImageAttachment from '../attachments/Image.vue'
import VideoAttachment from '../attachments/Video.vue'
import DocumentAttachment from '../attachments/Document.vue'
import ComplaintUploadFilesDialog from './ComplaintUploadFilesDialog.vue'
import EventBus from '@/services/event-bus'
import { Point } from 'ol/geom'
export default {
  props: { complaintId: Number, wards: Array, statuses: Array },
  components: { DragResize, ImageAttachment, VideoAttachment, DocumentAttachment, ComplaintUploadFilesDialog },
  data() {
    return {
      icons: { mdiAdjust, mdiClose, mdiReload, mdiPlus, mdiCrosshairs },
      tab: 0,
      complaint: null,
      deleteComplaintFile,
      showComplaintUploadFilesDialog: false,
      auditTrails: [],
      remarks: [],
      remark: '',
      attachments: {
        images: [],
        documents: [],
        videos: [],
        files: [],
      },
      files: [],
      form: {
        wardId: null,
        statusId: null,
      },
    }
  },
  inject: ['mapId'],

  computed: {
    ...mapState('auth', ['user']),
    role() {
      return this.user?.role?.code || 'field_officer'
    },
  },

  watch: {
    complaintId() {
      this.onComplaintChanged()
      this.getRemarks()
    },
  },

  methods: {
    zoomToLocation(location) {
      const point = new Point(location).transform('EPSG:4326', 'EPSG:3857')
      EventBus.$emit(`${this.mapId}-flash-geometry`, point)
    },
    async onComplaintChanged() {
      if (!this.complaintId) {
        this.complaint = null
        return
      }
      await this.getComplaint()
      this.form.statusId = this.complaint.statusId
    },

    close() {
      this.$emit('on-close')
    },

    async getComplaint() {
      try {
        this.$loader(true)
        const { data } = await getComplaint(this.complaintId)
        this.complaint = data
        this.auditTrails = data.complaintAuditTrails
        this.attachments.files = data.adminAttachments
        this.attachments.images = data.complaintAttachments.filter(attachment => attachment.type === 'image')
        this.attachments.documents = data.complaintAttachments.filter(attachment => attachment.type === 'doc')
        this.attachments.videos = data.complaintAttachments.filter(attachment => attachment.type === 'video')
      } finally {
        this.$loader(false)
      }
    },

    async getRemarks() {
      if (!this.complaintId) {
        this.remarks = []
        return
      }
      try {
        this.$loader(true)
        const { data } = await getRemarks(this.complaintId)
        this.remarks = data
      } finally {
        this.$loader(false)
      }
    },

    async createRemark() {
      if (!this.remark) return
      try {
        this.$loader(true)
        await createRemark(this.complaintId, this.remark)
        this.remark = ''
        this.getRemarks()
      } finally {
        this.$loader(false)
      }
    },

    async updateComplaint() {
      try {
        this.$loader(true)
        await updateComplaint(this.complaint.id, this.form)
        this.getComplaint()
        this.$message('Updated Successfully', 'success')
        this.$emit('on-updated', this.form)
      } finally {
        this.$loader(false)
      }
    },
  },
}
</script>
