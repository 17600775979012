<template>
  <div>
    <v-card outlined>
      <v-card-text class="py-2">
        <div class="d-flex">
          <span style="font-size: 1rem" class="font-weight-bold">ID: {{ complaint.id }} </span>
          <div class="ml-auto">
            <v-icon title="Zoom to" size="18" @click="$emit('on-zoom')">{{ icons.mdiCrosshairs }}</v-icon>
            <v-icon title="Show detail" size="18" @click="$emit('on-show-complaint')" right>{{
              icons.mdiInformationOutline
            }}</v-icon>
            <v-icon title="Export" size="21" @click="exportComplaint" right>{{ icons.mdiPdfBox }}</v-icon>
          </div>
        </div>
        <div class="d-flex mt-3">
          <span
            :title="complaint.name"
            class="font-italic"
            style="width: 150px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden"
            small
            outlined
          >
            {{ complaint.name }}</span
          >
          <span class="font-italic ml-auto" small outlined> {{ complaint.createdAt | date }}</span>
        </div>
        <div class="d-flex mt-3">
          <v-chip color="info" small outlined>{{ complaint.complaintStatus.name }}</v-chip>
          <span class="font-italic ml-auto" small outlined>W{{ complaint.ward.name }}</span>
        </div>
      </v-card-text>
    </v-card>
    <div style="height: 10px"></div>
  </div>
</template>
<script>
import {
  mdiCrosshairs,
  mdiInformationOutline,
  mdiDeleteOutline,
  mdiChevronDown,
  mdiChevronUp,
  mdiCrosshairsOff,
  mdiPdfBox,
} from '@mdi/js'
import { mapState } from 'vuex'
import { exportComplaint } from '@/api/complaint'
export default {
  props: {
    complaint: Object,
    cdSet: Object,
  },

  data() {
    return {
      icons: {
        mdiCrosshairs,
        mdiInformationOutline,
        mdiDeleteOutline,
        mdiChevronDown,
        mdiChevronUp,
        mdiCrosshairsOff,
        mdiPdfBox,
      },
    }
  },
  inject: ['mapId'],
  computed: {
    ...mapState('auth', ['user']),
    role() {
      return this.user?.role?.code || 'field_officer'
    },
  },
  methods: {
    zoomTo() {},
    async exportComplaint() {
      try {
        this.$loader(true)
        const data = await exportComplaint(this.complaint.id)
        const url = URL.createObjectURL(
          new Blob([data], {
            type: 'application/pdf',
          }),
        )
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `complaint-${this.complaint.id}.pdf`)
        document.body.appendChild(link)
        link.click()
      } finally {
        this.$loader(false)
      }
    },
  },
}
</script>
