<template>
  <v-card-text style="height: calc(100% - 56px)" class="pt-2">
    <v-text-field
      @click:clear="
        filter.id = null
        getComplaints(1)
      "
      @keyup.enter="getComplaints(1)"
      v-model.number="filter.id"
      :prepend-icon="icons.mdiSearchWeb"
      hide-details
      label="Search by ID"
      clearable
    >
      <template v-slot:append-outer>
        <ComplaintFilter
          :close-on-content-click="false"
          :width="310"
          :params="filter"
          :options="{ wards, statuses }"
          @on-reset="resetFilter"
          @change="getComplaints(1)"
          :menuProps="{
            offsetX: true,
            maxHeight: 250,
            right: true,
          }"
        />
      </template>
    </v-text-field>

    <div class="d-flex align-center mt-2">
      <span>Total: {{ total }}</span>
      <div class="ml-auto">
        <v-icon
          @click="showComplaintOnMap = !showComplaintOnMap"
          size="20"
          title="View in map"
          :color="showComplaintOnMap ? 'primary' : null"
          >{{ icons.mdiMap }}</v-icon
        >
        <v-menu offset-y left nudge-bottom="10">
          <template v-slot:activator="{ on, attrs }">
            <v-icon size="20" title="Export" class="ml-1" v-bind="attrs" v-on="on">{{ icons.mdiPdfBox }}</v-icon>
          </template>
          <v-list dense>
            <v-list-item @click="exportComplaints('pdf')">
              <v-list-item-title
                ><v-icon size="18" left>{{ icons.mdiFilePdfBox }}</v-icon> <span>PDF</span></v-list-item-title
              >
            </v-list-item>
            <v-list-item @click="exportComplaints('csv')">
              <v-list-item-title
                ><v-icon size="18" left>{{ icons.mdiFileExcel }}</v-icon> <span>CSV</span></v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu offset-y left nudge-bottom="10">
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="ml-1" size="20" title="Sort" v-bind="attrs" v-on="on">{{ icons.mdiSwapVertical }}</v-icon>
          </template>
          <v-card>
            <v-card-text>
              <v-radio-group class="mt-0" dense hide-details mandatory v-model="sortBy" @change="getComplaints(1)">
                <v-radio label="ID: low to high" value="id:asc"></v-radio>
                <v-radio label="ID: high to low" value="id:desc"></v-radio>
              </v-radio-group>
            </v-card-text>
          </v-card>
        </v-menu>

        <v-icon @click="getComplaints(1)" size="20" title="Refresh" class="ml-1">{{ icons.mdiReload }}</v-icon>
      </div>
    </div>
    <p v-if="complaints.length === 0" class="text-center mt-3">No complaint found</p>
    <RecycleScroller
      ref="scroller"
      v-scroll.self="onScroll"
      :items="complaints"
      :item-size="118"
      key-field="id"
      v-slot="{ item }"
      class="mt-4"
      style="height: calc(100% - 100px)"
    >
      <Complaint
        :complaint="item"
        @on-show-complaint="selectedComplaintId = +item.id"
        @on-zoom="zoomTo(item.geometry)"
      />
    </RecycleScroller>
    <ComplaintDetail
      :complaint-id="selectedComplaintId"
      :wards="wards"
      :statuses="statuses"
      @on-close="selectedComplaintId = null"
      @on-updated="updateComplaint"
    />
  </v-card-text>
</template>
<script>
import {
  mdiPdfBox,
  mdiPlus,
  mdiMap,
  mdiFilterOutline,
  mdiUploadOutline,
  mdiSearchWeb,
  mdiCompare,
  mdiReload,
  mdiSwapVertical,
  mdiFileExcel,
  mdiFilePdfBox,
} from '@mdi/js'
import Complaint from './Complaint'
import { getWards, getComplaintStatuses } from '@/api/category'
import { exportComplaints, getComplaints, getLayer } from '@/api/complaint'
import { RecycleScroller } from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import ComplaintDetail from './ComplaintDetail.vue'
import EventBus from '@/services/event-bus'
import { mapState } from 'vuex'
import GeoJSON from 'ol/format/GeoJSON'

import ComplaintFilter from '@/components/complaint/ComplaintFilter.vue'
export default {
  components: { RecycleScroller, Complaint, ComplaintDetail, ComplaintFilter },
  data() {
    return {
      icons: {
        mdiPdfBox,
        mdiPlus,
        mdiMap,
        mdiFilterOutline,
        mdiUploadOutline,
        mdiSearchWeb,
        mdiCompare,
        mdiReload,
        mdiSwapVertical,
        mdiFileExcel,
        mdiFilePdfBox,
      },
      selectedComplaintId: null,
      filter: {
        id: undefined,
        wards: [],
        statuses: [],
        page: 1,
      },
      layer: null,
      lastPage: 0,
      wards: [],
      statuses: [],
      showFilter: false,
      sortBy: 'id:desc',
      total: 0,
      lastPage: 0,
      complaints: [],
    }
  },
  inject: ['mapId'],
  computed: {
    ...mapState('map', ['layerIds']),
    isFilterActive() {
      return this.filter.wards.length > 0 || this.filter.statuses.length > 0
    },

    showComplaintOnMap: {
      get() {
        if (!this.layer) return false
        return this.layerIds.includes(this.layer.id)
      },
      set(value) {
        if (value) EventBus.$emit(`${this.mapId}-add-layer`, this.layer, { color: '#F44336' })
        else EventBus.$emit(`${this.mapId}-delete-layer`, this.layer.id)
      },
    },
  },
  watch: {
    layer() {
      if (!this.showComplaintOnMap) return
      const { id, tileUrl, extent } = this.layer
      EventBus.$emit(`${this.mapId}-update-vector-tile-layer`, id, tileUrl, extent)
    },
  },
  methods: {
    async getCategories() {
      try {
        const [wards, statuses, layer] = (
          await Promise.all([getWards({ sortBy: 'id:asc' }), getComplaintStatuses()])
        ).map(r => r.data)
        this.wards = wards
        this.statuses = statuses
      } catch (error) {
        console.log(error)
      }
    },

    resetFilter(reload = true) {
      this.filter = {
        id: undefined,
        wards: [],
        statuses: [],
        createdIn: null,
        updatedIn: null,
        page: 1,
      }
      this.showFilter = false
      if (reload) this.getComplaints()
    },

    async getComplaints(page = undefined) {
      try {
        if (page) {
          this.filter.page = page
        }
        this.$loader(true)
        const { data, meta } = await getComplaints({
          ...this.filter,
          perPage: 10,
          with: 'complaintStatus:id*name;ward:id*name;complaintStatus:id*name',
          sortBy: this.sortBy,
        })
        if (this.filter.page === 1) {
          const { data: layer } = await getLayer({
            id: this.filter.id,
            wards: this.filter.wards,
            statuses: this.filter.statuses,
          })
          this.layer = layer
          this.$refs['scroller'].$el.scrollTo({ top: 0 })
        }
        this.lastPage = meta.lastPage
        this.total = meta.total
        this.$nextTick(() => (this.complaints = this.filter.page === 1 ? data : this.complaints.concat(data)))
      } catch (error) {
        console.error(error)
      } finally {
        this.$loader(false)
      }
    },

    onScroll(e) {
      const { scrollTop, clientHeight, scrollHeight } = e.target
      if (scrollTop + clientHeight >= scrollHeight) {
        if (this.filter.page < this.lastPage) {
          this.filter.page++
          this.getComplaints()
        }
      }
    },
    
    updateComplaint({ statusId }) {
      const complaint = this.complaints.find(c => c.id === this.selectedComplaintId)
      if (!complaint) return
      complaint.statusId = statusId
      complaint.complaintStatus = this.statuses.find(s => s.id === statusId)
    },

    async viewComplaintInMap(id) {
      this.showComplaintOnMap = true
      this.resetFilter(false)
      this.filter.id = id
      await this.getComplaints()
      const complaint = this.complaints[0]
      this.zoomTo(complaint.geometry)
      this.selectedComplaintId = complaint.id
    },

    async getLayer() {
      try {
        const { data } = await getLayer()
        this.layer = data
      } catch (error) {
        console.error(error)
      }
    },

    async exportComplaints(type) {
      this.$loader(true)
      const { page, ...params } = this.filter
      const data = await exportComplaints({ ...params, sortBy: this.sortBy }, type)
      const url = URL.createObjectURL(
        new Blob([data], {
          type: type === 'csv' ? 'text/csv' : 'application/pdf',
        }),
      )
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `complaints.${type}`)
      document.body.appendChild(link)
      link.click()
      this.$loader(false)
    },

    zoomTo(geometry) {
      EventBus.$emit(
        `${this.mapId}-flash-geometry`,
        new GeoJSON().readGeometry(geometry, {
          dataProjection: 'EPSG:4326',
          featureProjection: 'EPSG:3857',
        }),
      )
    },
  },
  mounted() {
    const { complaintId } = this.$route.params
    this.getCategories()
    if (complaintId) {
      this.viewComplaintInMap(complaintId).then(() => (this.showComplaintOnMap = true))
    } else this.getComplaints().then(() => (this.showComplaintOnMap = true))
    EventBus.$on('view-complaint-in-map', this.viewComplaintInMap)
  },

  beforeDestroy() {
    EventBus.$off('view-complaint-in-map', this.viewComplaintInMap)
  },
}
</script>
