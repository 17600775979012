<template>
  <v-menu v-model="showFilter" offset-y v-bind="$attrs" left nudge-bottom="10">
    <template v-slot:activator="{ on, attrs }">
      <v-btn small color="primary" icon title="Filter Change Detection" v-bind="attrs" v-on="on">
        <v-icon>{{ isFiltered ? icons.mdiFilter : icons.mdiFilterOutline }}</v-icon>
      </v-btn>
    </template>
    <v-card :width="width">
      <v-card-title>Filter</v-card-title>
      <v-card-text class="pb-0">
        <v-autocomplete
          clearable
          multiple
          v-model="params.wards"
          :items="options.wards"
          label="Ward"
          item-text="name"
          item-value="id"
          dense
          :menu-props="menuProps"
        ></v-autocomplete>

        <v-autocomplete
          clearable
          multiple
          v-model="params.statuses"
          :items="options.statuses"
          label="Status"
          item-text="name"
          item-value="id"
          dense
          :menu-props="menuProps"
        ></v-autocomplete>
        <DateRangePicker
          v-model="params.createdIn"
          label="Created In"
          dense
          :hide-details="false"
          :max="new Date().toISOString().slice(0, 10)"
          display-format="MMM dd, yyyy"
          placeholder="Pick 2 Dates"
        />
        <DateRangePicker
          v-model="params.updatedIn"
          label="Updated In"
          dense
          :hide-details="false"
          :max="new Date().toISOString().slice(0, 10)"
          display-format="MMM dd, yyyy"
          placeholder="Pick 2 Dates"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          @click="
            $emit('on-reset')
            showFilter = false
          "
          color="primary"
          >Reset</v-btn
        >
        <v-btn
          text
          @click="
            $emit('change')
            showFilter = false
          "
          color="primary"
          >Apply</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-menu>
</template>
<script>
import { mdiFilterOutline, mdiFilter } from '@mdi/js'
import DateRangePicker from '../DateRangePicker.vue'
export default {
  props: {
    params: Object,
    options: Object,
    width: { type: Number, default: 400 },
    menuProps: {
      type: Object,
      default: () => ({
        offsetX: true,
        maxHeight: 250,
        left: true,
      }),
    },
  },
  components: { DateRangePicker },
  data() {
    return {
      icons: { mdiFilterOutline, mdiFilter },
      showFilter: false,
    }
  },

  computed: {
    isFiltered() {
      return (
        this.params.wards?.length > 0 ||
        this.params.statuses?.length > 0 ||
        this.params.createdIn?.length > 1 ||
        this.params.updatedIn?.length > 1
      )
    },
  },
}
</script>
